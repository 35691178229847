//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
    data() {
        return {
            button_wording: 'Order Pickup',
        };
    },
  props: {
    
    location: {
      type: Object,
      default: () => {}
    },
    orderType: {
      type: String,
      default: 'pickup'
    }
  },
  computed: {
    ...mapState([ 'order', 'options' ])
  },
  mounted() {
    console.log('this.location::: ', this.location)
    console.log('aaaaaaaaaaaaaaa: ', process.env.STAGGERED_ROLLOUT)
    let STAGGERED_ROLLOUT = process.env.STAGGERED_ROLLOUT
    // let STAGGERED_ROLLOUT = 'MCM001,NTN001,JCM008,CLV001,KNX015,KNXO20,MNT009,CDL001,CDR001,WRG003,NSH015,ATL047'

    if (STAGGERED_ROLLOUT) {
        let staggered_locations_arr = STAGGERED_ROLLOUT.split(',')

        if (staggered_locations_arr.includes(this.location.name)) {
            this.button_wording = 'Order Now'
        } else {
            this.button_wording = 'Order Pickup'
        }
    }
    
  },
  methods: {
    async startOrder() {
      this.$store.commit('setLocation', this.location)
      await this.$api.getMenu()
      let order = null
      if(this.order) {
        order = await this.$api.transferOrder()
        if(!order) {
          order = await this.$api.createOrder()
        }
        order = await this.$api.setHandoff('pickup')
        if(order && order.totals.tip > 0) {
          await this.$api.addTip(0)
        }
      } else {
        order = await this.$api.createOrder()
      }
      if(order) {
        if(this.orderType == 'delivery') {
          let order2 = await this.$api.setAddress(this.$store.state.customer.delivery)
          if(order2) {
            this.$router.push('/order/')
          }
        } else {
          await this.$api.setHandoff('pickup')
          this.$router.push('/order/')
        }
      }
    },
    setStore() {
      this.$store.commit('setLocation', this.location)
      this.$store.commit('setOrder', null)
      this.$router.push('/menu/')
    }
  }
}
