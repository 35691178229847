//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  props: {
    orderType: {
      type: String,
      default: 'pickup'
    },
    building: {
      type: String,
      default: ''
    },
    instructions: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState([ 'loading', 'locationList' ])
  }
}
