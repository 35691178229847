//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'

export default {
  data() {
    return {
      orderType: 'pickup',
      placeholder: 'Search store, city, state, or zip',
      address: null,
      building: null,
      instructions: null,
      suggestions: [],
      deliveryError: false
    }
  },
  mounted() {
    if (this.$route.query.delivery == 'true') {
      this.setOrderType('delivery')
    }
    this.$store.commit('customer/resetDeliveryAddress')
    this.$store.commit('setLocationList', null)
    if(this.$route.query.search) {
      this.address = this.$route.query.search
      this.locationSearch({ address: this.address })
    }
  },
  methods: {
    ...mapActions([ 'locationSearch' ]),
    setOrderType(type) {
      this.$store.commit('customer/resetDeliveryAddress')
      this.$store.commit('setLocationList', null)
      if(type == 'delivery') {
        this.orderType = 'delivery'
        this.placeholder = 'Enter a delivery address'
      } else {
        this.suggestions = []
        this.orderType = 'pickup'
        this.placeholder = 'Search store, city, state, or zip'
      }
    },
    searchLocations() {
      if(this.orderType == 'pickup') {
        this.locationSearch({ address: this.address })
      // } else if (this.orderType == 'delivery') {
      //   console.log(this.orderType)
      //   console.log(fullAddress)
      }
    },
    geoLocate() {
      if(navigator.geolocation) {
        this.$store.commit('setLoading', true)
        navigator.geolocation.getCurrentPosition(this.getCoordinates, this.getCoordinatesError)
      } else {
        this.$store.commit('setErrors', ['Please enable location services and try again.'])
      }
    },
    getCoordinates(position) {
      let coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      this.$api.findLocations(coords)
    },
    getCoordinatesError() {
      this.$store.commit('setLoading', false)
      this.$store.commit('setErrors', ['We couldn’t locate you. Please enter your address.'])
    },
    handleBlur() {
      let address = JSON.parse(JSON.stringify(this.$store.state.customer.delivery))
      address.building = this.building
      address.instructions = this.instructions
      console.log(address)
      this.$store.commit('customer/setDeliveryAddress', address)
    },
    handleKeyUp(e) {
      if(this.orderType == 'delivery' && e.key != 'Shift' && this.address.length > 4) {
        let newAddress = this.address.split(" ")
        let streetNum = /^[0-9]+$/.test(newAddress[0])
        
        if (streetNum) {
          this.deliveryError = false
          this.$axios({
            method: 'get',
            url: `${window.location.origin}/api/autocomplete`,
            params: {
              query: this.address
            }
          }).then(res => {
            this.suggestions = res.data.features
          }).catch(err => {
            this.resetAutocomplete()
          })
        } else {
          this.deliveryError = true
          this.suggestions = []
        }
      }
    },
    resetAutocomplete() {
      this.suggestions = []
    }
  }
}
