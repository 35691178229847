import { render, staticRenderFns } from "./LocationsSearch.vue?vue&type=template&id=a5558154&scoped=true&"
import script from "./LocationsSearch.vue?vue&type=script&lang=js&"
export * from "./LocationsSearch.vue?vue&type=script&lang=js&"
import style0 from "./LocationsSearch.vue?vue&type=style&index=0&id=a5558154&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5558154",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LocationSuggestions: require('/opt/build/repo/components/locations/LocationSuggestions.vue').default,LocationsResults: require('/opt/build/repo/components/locations/LocationsResults.vue').default})
