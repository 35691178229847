//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    suggestions: {
      type: Array,
      default: () => []
    },
    building: {
      type: String,
      default: ''
    },
    instructions: {
      type: String,
      default: ''
    },
    onSelect: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    selectAddress(s) {
      let address = {
        address: s.properties.address ? s.properties.address : `${s.address} ${s.text}`,
        building: this.$data.building,
        instructions: this.$data.instructions
      }
      console.log(address)
      s.context.map(c => {
        if(c.id.match(/^place/)) {
          address.city = c.text
        } else if(c.id.match(/^postcode/)) {
          address.zip = c.text
        }
      })
      this.$store.commit('customer/setDeliveryAddress', address)
      let coords = {
        lat: s.center[1],
        lng: s.center[0]
      }
      this.$api.findLocations(coords)
      this.onSelect()
    }
  }
}
